import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import * as lib from './lib';

export type Timestamp =
    | firebase.firestore.Timestamp
    | Date
    | firebase.firestore.FieldValue
    | string;
// export type Timestamp = firebase.firestore.Timestamp;

export interface Roles {
    [prop: string]: string;
}

export interface aspExtension {
    [prop: string]: string;
}

export type Address = {
    country?: string;
    state?: string;
    city?: string;
    address1?: string;
    address2?: string;
    postCode?: string;
};

export type Organization = {
    name?: string;
    unit?: string;
    tel?: string;
    fax?: string;
    address?: Address;
    contactPerson?: string;
    email?: string;
};

export type BankAccount = {
    name?: string;
    branch?: string;
    branchNumber?: string;
    type?: string;
    number?: string;
    holder?: string;
};

export type Client = {
    displayName: string;
    description?: string;
    organization?: Organization;
    createdAt: Timestamp;
    disabled: boolean;
    disabledAt?: Timestamp;
    deletedAt: Timestamp;
    serviceId?: string;
};

export type Promotion = {
    displayName: string;
    description?: string;
    uriPrefix: string;
    members: string[];
    roles: Roles;
    siteUri: string;
    labels?: string[];
    client?: string;
    createdAt: Timestamp;
    disabled: boolean;
    disabledAt?: Timestamp;
    deletedAt: Timestamp;
    serviceId?: string;
};

export type Partner = {
    displayName: string;
    description?: string;
    organization?: Organization;
    bankAccount?: BankAccount;
    createdAt: Timestamp;
    disabled: boolean;
    disabledAt?: Timestamp;
    deletedAt: Timestamp;
    serviceId?: string;
};

export type Site = {
    displayName: string;
    description?: string;
    members: string[];
    roles: Roles;
    siteUri?: string;
    category?: string[];
    partner?: string;
    createdAt: Timestamp;
    disabled: boolean;
    disabledAt?: Timestamp;
    deletedAt: Timestamp;
    serviceId?: string;
    aspConnect?: string;
    aspExtension?: aspExtension;
};

export type Approval = {
    site: string;
    promotion: string;
    status: string;
    answeredAt?: Timestamp;
    createdAt: Timestamp;
    disabled: boolean;
    disabledAt?: Timestamp;
    deletedAt: Timestamp;
    serviceId?: string;
};

export type Link = {
    type: string;
    uri: string;
    uriPrefix: string;
    redirectUri: string;
    promotion: string;
    promotionName?: string;
    site?: string;
    siteName?: string;
    partner?: string;
    partnerName?: string;
    client?: string;
    clientName?: string;
    createdAt: Timestamp;
    disabled: boolean;
    disabledAt?: Timestamp;
    deletedAt: Timestamp;
    serviceId?: string;
};

export const InitializeFirebase = () => {
    const firebaseConfig = {
        apiKey: 'AIzaSyBZ7P8NVNnAqbYy8vLnivFqD107QRghDrY',
        authDomain: 'ad-link-net.firebaseapp.com',
        databaseURL: 'https://ad-link-net.firebaseio.com',
        projectId: 'ad-link-net',
        storageBucket: 'ad-link-net.appspot.com',
        messagingSenderId: '1062302185389',
        appId: '1:1062302185389:web:9f18320cb19456fa475324',
        measurementId: 'G-5W7FKQQQLG',
    };
    firebase.initializeApp(firebaseConfig);
    firebase.auth().languageCode = 'ja';
};

export const ServerTimestamp = (): Timestamp => {
    return firebase.firestore.FieldValue.serverTimestamp() as Timestamp;
};
export class Store {
    firestore: firebase.firestore.Firestore;
    prefix: string;

    constructor(prefix: string) {
        this.firestore = firebase.firestore();
        this.prefix = prefix;
    }

    terminate(): Promise<void> {
        return this.firestore.terminate();
    }

    promotionSet(p: Promotion): Promise<firebase.firestore.DocumentReference> {
        return this.firestore.collection(`${this.prefix}promotions`).add(p);
    }

    async promotions(uid: string, serviceId: string) {
        const qsnap = await this.firestore
            .collection(`${this.prefix}-promotions`)
            .where('deletedAt', '==', null)
            .where('disabled', '==', false)
            .where('serviceId', '==', serviceId)
            .where('members', 'array-contains', uid)
            .limit(100)
            .get();
        return qsnap.docs;
    }

    async promotion(id: string) {
        const qsnap = await this.firestore
            .collection(`${this.prefix}-promotions`)
            .doc(id)
            .get();
        return qsnap;
    }

    async partner(id: string) {
        const qsnap = await this.firestore
            .collection(`${this.prefix}-partners`)
            .doc(id)
            .get();
        return qsnap;
    }

    async linksAsConversion(id: string) {
        const qsnap = await this.firestore
            .collection(`${this.prefix}-links`)
            .where('type', '==', 'conversion')
            .where('promotion', '==', id)
            .get();
        return qsnap.docs;
    }

    async linksAsClick(sid: string, pid: string) {
        if (pid) {
            const qsnap = await this.firestore
                .collection(`${this.prefix}-links`)
                .where('site', '==', sid)
                .where('promotion', '==', pid)
                .where('type', '==', 'click')
                .where('deletedAt', '==', null)
                .get();
            return qsnap.docs;
        } else {
            const qsnap = await this.firestore
                .collection(`${this.prefix}-links`)
                .where('site', '==', sid)
                .where('type', '==', 'click')
                .where('deletedAt', '==', null)
                .get();
            return qsnap.docs;
        }
    }

    async sites(uid: string, serviceId: string) {
        const qsnap = await this.firestore
            .collection(`${this.prefix}-sites`)
            .where('deletedAt', '==', null)
            .where('disabled', '==', false)
            .where('serviceId', '==', serviceId)
            .where('members', 'array-contains', uid)
            .orderBy('displayName')
            .limit(200)
            .get();
        return qsnap.docs;
    }

    async site(id: string) {
        const qsnap = await this.firestore
            .collection(`${this.prefix}-sites`)
            .doc(id)
            .get();
        return qsnap;
    }

    async approvalBySiteId(id: string) {
        const qsnap = await this.firestore
            .collection(`${this.prefix}-approvals`)
            .where('site', '==', id)
            .get();
        return qsnap.docs;
    }

    async approvalByPromotionId(id: string) {
        const qsnap = await this.firestore
            .collection(`${this.prefix}-approvals`)
            .where('promotion', '==', id)
            .get();
        return qsnap.docs;
    }
}

const hello = async (id: string) => {
    const response = await fetch('http://localhost:1234/' + id);
    const json = await response.json();
    return json;
};

export type SummarizeParam = {
    start: string;
    end: string;
    promotionId: string[];
    siteId: string[];
    dateLabel: string;
    promotionLabel?: string;
    siteLabel?: string;
    sessionMode?: string;
    serviceId: string;
};

const summarize = async (h: string, data: SummarizeParam) => {
    const uri = `${h}/summarize/1.0.0`;
    const response = await fetch(uri, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        // cache: 'force-cache',
    });
    const json = await response.json();
    return json;
};

export type ConversionsParam = {
    start: string;
    end: string;
    promotionId: string[];
    siteId: string[];
    sort: string;
    sessionMode?: string;
    serviceId: string;
};

const conversions = async (h: string, data: ConversionsParam) => {
    const uri = `${h}/conversions/1.0.0`;
    const response = await fetch(uri, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        // cache: 'force-cache',
    });
    const json = await response.json();
    return json;
};
export const API = {
    hello: hello,
    summarize: summarize,
    conversions: conversions,
};
